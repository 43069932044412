var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          height: "100%",
          width: "100%",
          "z-index": "1",
          "min-height": "250px",
          "max-height": "800px"
        },
        attrs: { id: _vm.getId }
      },
      [_c("div", { ref: "slot" }, [_vm._t("default")], 2)]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }